import React from 'react';
import Select from "react-select";
import {Field, Form} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import * as constants from './resource';

export default function ApplicationContainer(props) {
 
  const {customer, onSave, onNext, application, isMobile} = props;
    
  const validate = () => {
    const errors = {};
    if (!application["position"] || application["position"] === '') {
      errors["position"] = "Required";
    }
    else {
      const position = application["position"];

      if (position.isSchoolEmployee) {
        if (!application["job"] || application["job"] === '') {
          errors["job"] = "Required";
        }


        if (application["isNewEmployee"] === null) {
          errors["isNewEmployee"] = "Required";
        }

        if (application["isTeacher"] === null) {
          errors["isTeacher"] = "Required";
        }
      }
    }

    return errors;
  }

  const handleSubmit = () => {
    onNext()
  }

  return (         
    <Form
    onSubmit={handleSubmit} 
    validate={validate}
    >
    {({ handleSubmit, form }) => {
      return (
        <form onSubmit={handleSubmit}>
          
          <OnChange name="job">
            {(newValue) => {
              onSave({
                ...application,
                job: newValue,
              });
            }}
          </OnChange>

          <OnChange name="isNewEmployee">
            {(newValue) => {
              onSave({
                ...application,
                isNewEmployee: newValue === "true",
              });
            }}
          </OnChange>

          <OnChange name="isTeacher">
            {(newValue) => {
              onSave({
                ...application,
                isTeacher: newValue === "true",
              });
            }}
          </OnChange>
          <fieldset>
            <div id="candidateInformationContainer">
              <div>
                If you are applying for a paid position within one of our schools, please note that a Department of Child Services search is now required by the State of Indiana, effective July 1, 2016, in every state that you have resided since the age of 18. If you have lived in a state besides Indiana since the age of 18 Barada Associates may be emailing you the necessary documents to complete the newly required search in those states. Please promptly complete the forms and be sure to follow any directions provided on the form or by Barada Associates to prevent any delays in processing your background check. Thank you.
              </div>

              <div className={isMobile ? '' : "rowDisplay"}>
                <div className={isMobile ? 'width100' : 'third'}>
                  <label className='fieldLabel width90'>First Name</label>
                  <label className='width90'>{application["first"]}</label>
                </div>

                <div className={isMobile ? 'width100' : 'third'}>
                  <label className='fieldLabel width90'>Last Name</label>
                  <label className='width90'>{application["last"]}</label>
                </div>

                <div className={isMobile ? 'width100' : 'third'}>
                  <label className='fieldLabel width90'>Email Address</label>
                  <label className='width90'>{application["email"]}</label>
                </div>
              </div>

              <div>
                <label className='fieldLabel width90'>Parish/School/Agency City </label>
                <label className='width90'>{customer["city"]}</label>
              </div>

              <div>
                <label className='fieldLabel width90'>Name the parish/school/agency requesting the background check </label>
                <label className='width90'>{customer["dropdownText"]}</label>
              </div>

              <Field name="position">
                {({ input, meta }) => (
                  <div>
                    <label className='fieldLabel width100'>Please indicate your position/duty with the Parish/School/Agency <span className='red'>*</span></label>
                    <Select
                      {...input}
                      options={constants.POSITION_OPTIONS}
                      id="position"
                      label="Please indicate your position/duty with the Parish/School/Agency"
                      placeholder={"Select..."}
                      menuPlacement="top"
                      className='selectField width90'
                      value={application.position}
                      onChange={(e) => { 
                        onSave({...application, position: e});
                    }}
                    />
                    {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                  </div>
                )}
              </Field>

              {application.position?.isSchoolEmployee && (
                <>
                  <div className='red'>
                    Please note that you will receive an email from Barada Associates within two business days requesting a form for the Department of Child Services to be completed and returned to Barada Associates.
                  </div>

                  <Field name="job">
                    {({ input, meta }) => (
                      <div className='width90'>
                        <label className='fieldLabel'>Specific Job Title or Program (if you are a volunteer, list any and all activities in which you plan to volunteer) <span className='red'>*</span></label>
                        <input {...input} className={isMobile ? 'width90 textField' : 'third textField'} type="text" placeholder="Job Title" value={application.job} />
                        {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                  
                  <Field name="isNewEmployee">
                    {({ input, meta }) => (
                      <div>
                        <label className='fieldLabel'>Which of the following applies to you? <span className='red'>*</span></label>
                        <input {...input} className="radioField" type="radio" value="true" id="isNewEmployee" name="isNewEmployee"
                          checked={application.isNewEmployee === true}
                        
                        />New Employee
                      </div>
                    )}
                  </Field>

                  <Field name="isNewEmployee">
                    {({ input, meta }) => (
                      <div>
                        <input {...input} className="radioField" type="radio" value="false" id="isNewEmployee" name="isNewEmployee" checked={application.isNewEmployee === false} />Current Employee or Rehire which diosese (if your previous employment ended more than 1 year ago, please select New Employee)
                        {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                      </div>
                    )}
                  </Field>

                  <Field name="isTeacher">
                    {({ input, meta }) => (
                      <div>
                        <label className='fieldLabel'>Are you current a Teacher, Substitute, Vice Principal or Principal or applying to be one? <span className='red'>*</span></label>
                        <input {...input} className="radioField" type="radio" value="true" name="isTeacher" id="isTeacher" checked={application.isTeacher === true}/>Yes
                      </div>
                    )}
                  </Field>

                  <Field name="isTeacher">
                    {({ input, meta }) => (
                      <div>
                        <input {...input} className="radioField" type="radio" value="false"  name="isTeacher" id="isTeacher" checked={application.isTeacher === false} />No

                        {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </>

              )}
            </div>
            <div>
              <button onClick={handleSubmit}>NEXT</button>
            </div>
          </fieldset>
        </form>
      );

    }}
    </ Form>
  );
}


