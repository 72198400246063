import React from 'react';
import {Form} from 'react-final-form';
import disclosure from './2024 FCRA Summary of Rights.pdf';

export default function DisclosureContainer(props) {
  const {onNext} = props;
  
  
  const handleSubmit = () => {
    onNext()
  }

  return (         
      <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        
        <fieldset>
          <div id="disclosureContainer">
            <iframe src={disclosure} width="100%" height="500px" title="disclosurePdf">
            </iframe>
          </div>
          <div>
            <button type='button' onClick={props.onBack}>BACK</button>
            <button type='submit'>NEXT</button>
          </div>
        </fieldset>
      </form>
    )}
    />
  );
}