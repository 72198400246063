import React from 'react';
import Select from "react-select";
import {Field, Form} from 'react-final-form';
import * as constants from './resource';
import {OnChange} from 'react-final-form-listeners';

export default function ReleaseContainer(props) {
  const {onSave, onNext, releaseDetails, isMobile} = props;
    
  const validate = () => {
    const errors = {};

    const requiredFields = ["releaseFirstName", "releaseMiddleName", "releaseLastName", "releaseDateOfBirth", "releasePhone", "releaseAddressLine1", "releaseCity", "releaseState", "releaseZip"]

    requiredFields.forEach(field => {
      if (!releaseDetails[field] || releaseDetails[field] === '') {
        errors[field] = "Required";
      }        
    });

    //  VALIDATE PHONE
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const releasePhone = releaseDetails["releasePhone"];
    if (!phoneRegex.test(releasePhone)) {
      errors["releasePhone"] = "Please enter a phone number in the (###) ###-#### format";
    };

    //  VALIDATE ZIP
    const zipRegex = /^\d{5}(-\d{4})?$/;
    const zipCode = releaseDetails["releaseZip"];
    if (!zipRegex.test(zipCode)) {
      errors["releaseZip"] = "Please enter a 5 or 9 digit zip code.";
    }

    //  VALIDATE DOB
    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const dob = releaseDetails["releaseDateOfBirth"];
    if (!dateRegex.test(dob)) {
      errors["releaseDateOfBirth"] = "Please enter a date of birth using the mm/dd/yyyy format";
    }

    return errors;
  }
    
    const handleSubmit = () => {
      onNext()
    }

    return (         
        <Form
        onSubmit={handleSubmit}
        validate={validate}
        >
        {({ handleSubmit, form }) => {
          return (
            <form onSubmit={handleSubmit}>


              <OnChange name="releaseFirstName">
                {(newValue) => {
                  onSave({
                    ...releaseDetails,
                    releaseFirstName: newValue,
                  });
                }}
              </OnChange>
              <OnChange name="releaseMiddleName">
                {(newValue) => {
                  onSave({
                    ...releaseDetails,
                    releaseMiddleName: newValue,
                  });
                }}
              </OnChange>
              <OnChange name="releaseLastName">
                {(newValue) => {
                  onSave({
                    ...releaseDetails,
                    releaseLastName: newValue,
                  });
                }}
              </OnChange>
              <OnChange name="releaseDateOfBirth">
                {(newValue) => {
                  onSave({
                    ...releaseDetails,
                    releaseDateOfBirth: newValue,
                  });
                }}
              </OnChange>
              <OnChange name="releasePhone">
                {(newValue) => {
                  onSave({
                    ...releaseDetails,
                    releasePhone: newValue,
                  });
                }}
              </OnChange>
              <OnChange name="releaseOtherNames">
                {(newValue) => {
                  onSave({
                    ...releaseDetails,
                    releaseOtherNames: newValue,
                  });
                }}
              </OnChange>
              <OnChange name="releaseAddressLine1">
                {(newValue) => {
                  onSave({
                    ...releaseDetails,
                    releaseAddressLine1: newValue,
                  });
                }}
              </OnChange>
              <OnChange name="releaseAddressLine2">
                {(newValue) => {
                  onSave({
                    ...releaseDetails,
                    releaseAddressLine2: newValue,
                  });
                }}
              </OnChange>
              <OnChange name="releaseCity">
                {(newValue) => {
                  onSave({
                    ...releaseDetails,
                    releaseCity: newValue,
                  });
                }}
              </OnChange>
              <OnChange name="releaseZip">
                {(newValue) => {
                  onSave({
                    ...releaseDetails,
                    releaseZip: newValue,
                  });
                }}
              </OnChange>
              <fieldset>

                <div id="ApplicantReleaseContainer">
                  <h3 className='subtitle'>
                    For Consumer and Investigative Consumer Reports
                  </h3>

                  <div className='dislosureText'>
                    I authorize all licensing bodies, courts, law enforcement agencies, governmental agencies or departments and military services to provide information about my background, including...
                  </div>

                  <div className='dislosureText'>
                    I understand that an investigative consumer report that is obtained through interviews and may contain information about my character, general...
                    the consumer report secured by the Diocese of Lafayette will concern only criminal history information unless I am specifically tole otherwise.  Upon my written request within a reasonable...
                    be made to me in writing within five days of the date on which the request was received.
                  </div>

                  <div className='dislosureText'>I further authorize Diocese of Lafayette to request a consumer report and/or investigative consumer report about me, for employment/volunteering related purposes, at any time to the... future, in original, faxed, copied or electronic form.</div>

                  <div className='dislosureText'>I acknowledge that I have received a copy of the "Summary of Your Rights Under the Fair Credit Reporting Act."</div>

                  <div className='dislosureText'>I understand that my date of birth will be used solely for identification purposes.</div>

                  <h3 className='subtitle mt-50'>Applicant Info</h3>
                  <div className={isMobile ? '' : 'rowDisplay'}>
                    <div className={isMobile ? 'width100' : 'third'}>
                      <Field name="releaseFirstName">
                          {({ input, meta }) => (
                            <div className='width90'>
                              <label className='fieldLabel width100'>First Name <span className='red'>*</span></label>
                              <input {...input} className='textField width100' type="text" />
                              {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                            </div>
                          )}
                      </Field>
                    </div>

                    <div className={isMobile ? 'width100' : 'third'}>
                      <Field name="releaseMiddleName">
                          {({ input, meta }) => (
                            <div className='width90'>
                              <label className='fieldLabel width100'>Middle Name <span className='red'>*</span></label>
                              <input {...input} className='textField width100' type="text" />
                              {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                            </div>
                          )}
                      </Field>
                    </div>

                    <div className={isMobile ? 'width100' : 'third'}>
                      <Field name="releaseLastName">
                          {({ input, meta }) => (
                            <div className='width90'>
                              <label className='fieldLabel width100'>Last Name <span className='red'>*</span></label>
                              <input {...input} className='textField width100' type="text" />
                              {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                            </div>
                          )}
                      </Field>
                    </div>
                  </div>

                  <div className={isMobile ? '' : 'rowDisplay'}>
                    <div className={isMobile ? 'width100' : 'third'}>
                      <Field name="releaseDateOfBirth">
                          {({ input, meta }) => (
                            <div className='width90'>
                              <label className='fieldLabel width100'>Date of Birth (mm/dd/yyyy) <span className='red'>*</span></label>
                              <input {...input} className='textField width100' type="text" />
                              {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                            </div>
                          )}
                      </Field>
                    </div>

                    <div className={isMobile ? 'width100' : 'third'}>
                      <Field name="releasePhone">
                          {({ input, meta }) => (
                            <div className='width90'>
                              <label className='fieldLabel width100'>Phone <span className='red'>*</span></label>
                              <input {...input} className='textField width100' type="text" />
                              {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                            </div>
                          )}
                      </Field>
                    </div>
                  </div>

                  <div className={isMobile ? 'width100' : 'third'}>
                      <Field name="releaseOtherNames">
                          {({ input, meta }) => (
                            <div className='width100'>
                              <label className='fieldLabel'>Other Name(s)</label>
                              <input {...input} className='width90 textField' type="text" />
                              {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                            </div>
                          )}
                      </Field>
                  </div>
                  
                  <h3 className='subtitle mt-50'>Address Info</h3>

                  <div className={isMobile ? '' : 'rowDisplay'}>
                      <Field name="releaseAddressLine1">
                          {({ input, meta }) => (
                            <div className='width100'>
                              <label className='fieldLabel width100'>Address Line 1 <span className='red'>*</span></label>
                              <input {...input} className='width90 textField' type="text" />
                              {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                            </div>
                          )}
                      </Field>
                  </div>
                                          
                  <div className={isMobile ? '' : 'rowDisplay'}>
                      <Field name="releaseAddressLine2">
                          {({ input, meta }) => (
                            <div className='width100'>
                              <label className='fieldLabel width100'>Address Line 2</label>
                              <input {...input} className='width90 textField' type="text" />
                              {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                            </div>
                          )}
                      </Field>
                  </div>

                  <div className={isMobile ? '' : 'rowDisplay'}>
                    <div className={isMobile ? 'width100' : 'third'}>
                      <Field name="releaseCity">
                          {({ input, meta }) => (
                            <div className='width90'>
                              <label className='fieldLabel width100'>City <span className='red'>*</span></label>
                              <input {...input} className='textField width100' type="text" />
                              {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                            </div>
                          )}
                      </Field>
                    </div>

                    <div className={isMobile ? 'width100' : 'third'}>
                      <Field name="releaseState">
                        {({ input, meta }) => (
                          <div>
                            <label className='fieldLabel width90'>State <span className='red'>*</span></label>
                            <Select
                              {...input}
                              options={constants.STATES}
                              id="position"
                              label="State"
                              placeholder={"Select..."}
                              menuPlacement="top"
                              className={isMobile ? 'selectField width90' : 'selectField seventyFive'}
                              value={releaseDetails.releaseState}
                              onChange={(e) => { 
                                onSave({...releaseDetails, releaseState: e});
                            }}
                            />
                            {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                          </div>
                        )}
                      </Field>
                    </div>

                  <div className={isMobile ? 'width100' : 'third'}>
                    <Field name="releaseZip">
                        {({ input, meta }) => (
                          <div className='width90'>
                            <label className='fieldLabel width100'>Zip <span className='red'>*</span></label>
                            <input {...input} className='textField width100' type="text" />
                            {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                          </div>
                        )}
                    </Field>
                  </div>
                </div>
              </div>

              <div>
                  <button onClick={props.onBack}>BACK</button>
                  <button onClick={handleSubmit}>SUBMIT</button>
              </div>
              
              </fieldset>
        </form>
      );
    }}
    </ Form>
  );
}


