import React, { useState, useEffect } from 'react';
import * as constants from './resource';
import LoadingOverlay from 'react-loading-overlay';
import CryptoJS from "crypto-js";
import SignatureContainer from './SignatureContainer';
import DisclosureContainer from './DisclosureContainer';
import ReleaseContainer from './ReleaseContainer';
import ApplicationContainer from './ApplicationContainer';



const logo = require('./Barada-Final-logo-300-white-shadow.png');

function App() {
  const [profileNumber, setProfileNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false)
  const [key, setKey] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [customer, setCustomer] = useState({
    compassLocation:'', 
    customerId: '', 
    fullName: '', 
    shortName:'', 
    city: '', 
    dropdownText: ''
  })

  const [currentStep, setCurrentStep] = useState(1);

  const [application, setApplication] = useState({
    first: "",
    last: "",
    email: "",
    id: "",
    city: "",
    customerId: "",
    position: "",
    job: "",
    isNewEmployee: null,
    isTeacher: null,
    isSchoolEmployee: null
  })

  const [signatureDetails, setSignatureDetails] = useState({
    signatureAgreement: false,
    signatureImage: null,
    signatureData: null 
  })
  
  const [releaseDetails, setReleaseDetails] = useState({
    releaseFirstName: "",
    releaseMiddleName: "",
    releaseLastName: "",
    releaseDateOfBirth: "",
    releasePhone: "",
    releaseOtherNames: "",
    releaseAddressLine1: "",
    releaseAddressLine2: "",
    releaseCity: "",
    releaseState: "",
    releaseZip: ""
  })
  
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;



  function getEncryptedParameters() {
    const url = window.location.href;
    var questionIndex = url.indexOf("?");
    var decodedURIComponent = decodeURIComponent(url.substring(questionIndex+1));
    return decodedURIComponent.toString();
  }

  useEffect(() => {
    const decryptURL = async () => {
        
      setIsProcessing(true);

      let tempKey = "";

      const settings = {
        method: 'GET',
      };

      try {
        
        const response = await fetch(`/api/GetDecryptionKey`, settings);
        const result = await response.json();

        setErrorMessage(result.errorMessage);
        setKey(result.key);

        tempKey = result.key;

        console.log(result.key);
        // setKey("bRuD5WYw5wd0rdHR");
      } catch (error) {
        setErrorMessage(error);
        setKey("");
      }
      
      var encryptedParameterText = getEncryptedParameters();

      console.log(encryptedParameterText);

      var decryptedParameters = CryptoJS.AES.decrypt(encryptedParameterText, tempKey);

      console.log(decryptedParameters);

      var decryptedParameterText = decodeURIComponent(decryptedParameters.toString(CryptoJS.enc.Utf8));
      console.log(decryptedParameterText);


      var candidateDetails = {};
      var valueArray = decryptedParameterText.split("&");

      console.log(valueArray);

      for (const valueString of valueArray) {
        var valuePair = valueString.split("=");

        if (valuePair[0] === "location") {
          const completeLocation = valuePair[1].replaceAll("+", " ");

          // find the location in the Resource file
          const customer = constants.CUSTOMERS.find(x => x.compassLocation.toUpperCase() === completeLocation.toUpperCase());
          candidateDetails["city"] = customer.city;
          candidateDetails["customerId"] = customer.customerId;

          setCustomer(customer);
        }
        else{
          candidateDetails[valuePair[0]] = valuePair[1];
        }
      }

      // TODO: Remove random
      var rnd = Math.floor(Math.random() * (99999 - 5000 + 1) + 5000);
      candidateDetails["id"] = rnd;

      console.log(candidateDetails);

      setApplication(candidateDetails);
      setIsProcessing(false);
  }

  
  if (key === '' && !isProcessing) {
    decryptURL();
  }
}, [key, isProcessing]);


useEffect(() => {
  const initializeDatabase = async () => {
      
    const settings = {
      method: 'GET',
    };

    try {
      await fetch(`/api/InitializeDatabase`, settings);
    } 
    catch(error) {
      console.log(error);
      // catch but ignore
    }
  }

  initializeDatabase();
}, []);


const saveApplication = (values) => {
  setApplication({
    ...application,
    position: values.position,
    job: values.position.isSchoolEmployee ? (values.job || '') : '',
    isNewEmployee: values.position.isSchoolEmployee ? values.isNewEmployee : null,
    isTeacher: values.position.isSchoolEmployee ? values.isTeacher : null,
    isSchoolEmployee: values.position.isSchoolEmployee,
  })
}


const saveSignature = (values) => {
  setSignatureDetails({
    ...signatureDetails,
    signatureAgreement: values.signatureAgreement,
    signatureImage: values.signatureImage,
    signatureData: values.signatureData

  })
}

const saveReleaseDetails = (values) => {
  setReleaseDetails({
    ...releaseDetails,
    releaseFirstName: values.releaseFirstName,
    releaseMiddleName: values.releaseMiddleName,
    releaseLastName: values.releaseLastName,
    releaseDateOfBirth: values.releaseDateOfBirth,
    releasePhone: values.releasePhone,
    releaseOtherNames: values.releaseOtherNames,
    releaseAddressLine1: values.releaseAddressLine1,
    releaseAddressLine2: values.releaseAddressLine2,
    releaseCity: values.releaseCity,
    releaseState: values.releaseState,
    releaseZip: values.releaseZip
  })
}



const handleBack = () => {
  setCurrentStep(currentStep-1);
}

const handleNext = () => {
  setCurrentStep(currentStep+1);
}

const handleSubmit = async () => {
  setIsProcessing(true)
  var applicationData = {
    "first": application.first,
    "last": application.last,
    "email": "bgraffis@leafsoftwaresolutions.com", //  application.email,
    "id": application.id,
    "city": application.city,
    "customerId": application.customerId,
    "position": application.position.value,
    "job": application.position.isSchoolEmployee ? (application.job || '') : '',
    "isNewEmployee": application.position.isSchoolEmployee ? (application.isNewEmployee || '') : '',
    "isTeacher": application.position.isSchoolEmployee ? (application.isTeacher || '') : '',
    "isSchoolEmployee": application.position.isSchoolEmployee,

    "signatureAgreement": signatureDetails.signatureAgreement,
    "signatureImage": signatureDetails.signatureImage,
    "signatureData": signatureDetails.signatureData,

    "releaseFirstName": releaseDetails.releaseFirstName,
    "releaseMiddleName": releaseDetails.releaseMiddleName,
    "releaseLastName": releaseDetails.releaseLastName,
    "releaseDateOfBirth": releaseDetails.releaseDateOfBirth,
    "releasePhone": releaseDetails.releasePhone,
    "releaseOtherNames": releaseDetails.releaseOtherNames,
    "releaseAddressLine1": releaseDetails.releaseAddressLine1,
    "releaseAddressLine2": releaseDetails.releaseAddressLine2,
    "releaseCity": releaseDetails.releaseCity,
    "releaseState": releaseDetails.releaseState,
    "releaseZip": releaseDetails.releaseZip
  }

  console.log(applicationData);

  const settings = {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(applicationData)
  };

  try{
    try {
      const response = await fetch(`/api/SendBackgroundCheckRequest`, settings);
      const result = await response.json();

      setErrorMessage(result.errorMessage);
      setProfileNumber(result.profileNumber);

      setRedirectUrl(result.redirectUrl);
    } catch (error) {
      setErrorMessage(error);
      setProfileNumber("");
    }
  }
  catch ({ name, message }) {
    alert(message);
    setErrorMessage(message);
    setProfileNumber('');
  }


  setIsProcessing(false)

  
}

return <div>
    <div className='header' >
      <img alt='' src={String(logo)} className='logo' />
    </div>

    <LoadingOverlay
      active={isProcessing}
      spinner
      text='Please wait...'>

      <div className='rowDisplay'>
        
      <div className='gutter'></div>
        <div className={isMobile ? 'mobileBody' : 'body'}>
          <h2 className='title'>The Diocese Of Lafayette In Indiana</h2>
          <div className={isMobile ? 'mobileContents' : 'contents'}>
           {!profileNumber && (
            <>
              {currentStep === 1 && (
                <ApplicationContainer isMobile={isMobile} onNext={handleNext} onSave={saveApplication} customer={customer} application={application} />
              )}

              {currentStep === 2 && (
                <SignatureContainer isMobile={isMobile} onNext={handleNext} onSave={saveSignature} onBack={handleBack} signatureDetails={signatureDetails} />
              )}

              {currentStep === 3 && (
                <DisclosureContainer isMobile={isMobile} onNext={handleNext} onBack={handleBack}/>
               )}

              {currentStep === 4 && (
                <ReleaseContainer isMobile={isMobile} onNext={handleSubmit} onBack={handleBack} onSave={saveReleaseDetails} releaseDetails={releaseDetails} />
              )}
                          
              {errorMessage && (
                <div className='red'>There was an error submitting your request.  Contact Barada Associates at 800.616.5917 or <a href="mailto:customerservice@baradainc.com">customerservice@baradainc.com</a>.</div>
              )}
            </>
          )}

            {profileNumber && (
              <div>
                <h3 className='subtitle'>Submission Complete</h3>
                <div className='dislosureText'>Thank you.  Your background check application has been successfully submitted.</div>
                <div className='dislosureText'>You should soon receive an email at the address above with information on completing your request.</div>
                {redirectUrl && (
                  <div className='dislosureText'>Please <a href={redirectUrl}>here</a> to return to the training site.</div>
                )}
              </div>
            )}
          </div>
              
        </div>
        <div className='gutter'></div>
      </div>
    </LoadingOverlay>
  </div>
}

export default App;
