import React, { useRef, useEffect } from 'react';
import {Field, Form} from 'react-final-form';
import SignatureCanvas from 'react-signature-canvas'
import {OnChange} from 'react-final-form-listeners';

export default function SignatureContainer(props) {
    const {onSave, onNext, signatureDetails, isMobile} = props;
    const signaturePadRef = useRef({});

    const clearSigPad = () => {
        signaturePadRef.current.clear()

        props.onSave({
            ...signatureDetails,
            signatureImage: null,
            signatureData: null
        });
    }

    const validate = () => {
        const errors = {};

        if (!signatureDetails["signatureAgreement"]) {
            errors["signatureAgreement"] = "Required";
        }

        if (!signatureDetails["signatureImage"] || signatureDetails["signatureImage"] === null) {
            errors["signature"] = "Required";
        }

        return errors;
    }

    const handleSaveSignature = () => {
         const signatureImage = signaturePadRef.current.getTrimmedCanvas().toDataURL('image/png');
         const signatureData = signaturePadRef.current.toData();
  
         props.onSave({
             ...signatureDetails,
             signatureImage: signatureImage,
             signatureData: signatureData
         });
    }
    
    const handleSubmit = () => {
        onNext()
    }
    
    
    useEffect(() => {
        if (Object.keys(signaturePadRef.current).length > 0 && signatureDetails.signatureData !== null) {
            signaturePadRef.current.fromData(signatureDetails.signatureData);
        }
    });

    return (         
        <Form
        onSubmit={handleSubmit} 
        validate={validate}
        >
        {({ handleSubmit, form }) => {
            return (
                <form onSubmit={handleSubmit}>
                    <OnChange name="signatureAgreement">
                        {(newValue) => {
                            onSave({
                                ...signatureDetails,
                                signatureAgreement: newValue,
                            });
                        }}
                    </OnChange>

                    <fieldset>
                        <div id="signatureContainer">
                            <h3 className='subtitle'>Applicant disclosure</h3>
                            <div>
                                In connection with my application for employment or volunteer service with the Diocese of Lafayette, I understand that a consumer report (and/or investigative consumer report), as defined by the Fair Credit Reporting Act (FCRA), may be obtained for employment or volunteer service purposes.
                            </div>
                            
                            <Field name="signature">
                                {({ input, meta }) => (
                                <div>
                                    <label className='fieldLabel'>Applicant's signature:<span className='red'>*</span>
                                        <button className='buttonAsLink' onClick={() => clearSigPad()}>
                                            Clear
                                        </button>
                                    </label>
                                    <SignatureCanvas {...input} ref={signaturePadRef} penColor='black' backgroundColor="#F9F9F9" canvasProps={{width: isMobile ? 300 : 500, height: 200, className: 'sigCanvas'}} onEnd={handleSaveSignature}/>
                                    {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                                </div>
                                )}
                            </Field>
                                
                            <Field name="signatureAgreement">
                                {({ input, meta }) => (
                                <div>
                                    <input {...input} className="radioField" type="checkbox"  id="signatureAgreement" name="signatureAgreement" checked={signatureDetails.signatureAgreement === true}/>I Agree
                                    {meta.error && meta.touched && <span className='fieldError'>{meta.error}</span>}
                                </div>
                                )}
                            </Field>

                        </div>

                        <div>
                            <button onClick={props.onBack}>BACK</button>
                            <button onClick={handleSubmit}>NEXT</button>
                        </div>
                    </fieldset>
                </form>
            );
        }}
    </ Form>
  );
}


