//add flag for school fields
export const POSITION_OPTIONS = [
    { value: "Clergy", label: "Clergy", isSchoolEmployee: false },
    { value: "Church/Agency Only - Employee or Contractor", label: "Church/Agency Only - Employee or Contractor", isSchoolEmployee: false },
    { value: "School - Non-Teaching Employee or Contractor of School", label: "School - Non-Teaching Employee or Contractor of School", isSchoolEmployee: true },
    { value: "VOLUNTEER of Parish Agency or School", label: "VOLUNTEER of Parish Agency or School", isSchoolEmployee: false },
    { value: "School - Teacher, Principal, Contracted Teacher or Substitute Teacher of School", label: "School - Teacher, Principal, Contracted Teacher or Substitute Teacher of School", isSchoolEmployee: true }]

export const CUSTOMERS = [
{compassLocation:'CHURCH: Lebanon - St. Joseph', customerId: 'BARA_00885', fullName: 'DIO LAF St. Joseph Lebanon@', shortName:'Dio of Lafayette in IN St. Joseph Catholic Church (Lebanon)', city: 'Lebanon', dropdownText: 'St. Joseph Catholic Church'},
{compassLocation:'CHURCH: Fishers - Holy Spirit', customerId: 'BARA_00886', fullName: 'DIO LAF Holy Spirit (Geist)@', shortName:'Dio of Lafayette Holy Spirit Catholic Church (Geist)', city: 'Indianapolis', dropdownText: 'Holy Spirit Catholic Church (Geist)'},
{compassLocation:'CHURCH: West Lafayette - Church of the Blessed Sacrament', customerId: 'BARA_00887', fullName: 'DIO LAF Blessed Sacrament', shortName:'Dio of Lafayette in IN Church of the Blessed Sacrament (West Lafayette)', city: 'West Lafayette', dropdownText: 'Church of the Blessed Sacrament'},
{compassLocation:'CHURCH: Peru - St. Charles Borromeo', customerId: 'BARA_00888', fullName: 'DIO LAF St. Charles Borromeo', shortName:'Dio of Lafayette in IN St. Charles Borromeo Church (Peru)', city: 'Peru', dropdownText: 'St. Charles Barromeo Church'},
{compassLocation:'CHURCH: Tipton - St. John the Baptist', customerId: 'BARA_00889', fullName: 'DIO LAF St. John Baptist(Tip)@', shortName:'Dio of Lafayette in IN St. John the Baptist Church (Tipton)', city: 'Tipton', dropdownText: 'St. John the Baptist Church'},
{compassLocation:'CHURCH: Kokomo - St. Joan of Arc', customerId: 'BARA_00891', fullName: 'DIO LAF St. Joan of Arc', shortName:'Dio of Lafayette St. Joan of Arc (Kokomo)', city: 'Kokomo', dropdownText: 'St. Joan of Arc'},
{compassLocation:'OTHER: Lafayette - Pastoral Center', customerId: 'BARA_00892', fullName: 'DIO LAF HR Jeanne/Dyane/Fr.Ted', shortName:'Diocese of Lafayette in IN (Lafayette)', city: 'Lafayette', dropdownText: 'Diocese of Lafayette - Human Resources'},
{compassLocation:'CHURCH: Anderson - St. Mary', customerId: 'BARA_00897', fullName: 'DIO LAF St. Mary (Anderson)', shortName:'Dio of Lafayette St. Mary Parish (Anderson)', city: 'Anderson', dropdownText: 'St. Mary Parish'},
{compassLocation:'CHURCH: Frankfort - St. Mary', customerId: 'BARA_00899', fullName: 'DIO LAF St. Mary (Frankfort)@', shortName:'Dio of Lafayette St. Mary Catholic Church (Frankfort)', city: 'Frankfort', dropdownText: 'St. Mary Catholic Church'},
{compassLocation:'CHURCH: Kokomo - St. Patrick', customerId: 'BARA_00901', fullName: 'DIO LAF St. Patrick (Kokomo)', shortName:'Dio of Lafayette St. Patrick Catholic Church (Kokomo)', city: 'Kokomo', dropdownText: 'St. Patrick Catholic Church'},
{compassLocation:'SCHOOL: Noblesville - Our Lady of Grace School', customerId: 'BARA_00903', fullName: 'DIO LAF Our Lady of Grace@', shortName:'Dio of Lafayette Our Lady of Grace (Noblesville)', city: 'Noblesville', dropdownText: 'Our Lady of Grace'},
{compassLocation:'SCHOOL: Noblesville - Guerin Catholic High School', customerId: 'BARA_00905', fullName: 'DIO LAF Guerin Catholic HS', shortName:'Dio of Lafayette Guerin Catholic High School (Noblesville)', city: 'Noblesville', dropdownText: 'Guerin Catholic High School'},
{compassLocation:'CHURCH: Fishers - St. Louis de Montfort', customerId: 'BARA_00906', fullName: 'DIO LAF St. Louis de Montfort', shortName:'Dio of Lafayette St. Louis de Montfort Church & School (Fishers)', city: 'Fishers', dropdownText: 'St. Louis de Monfort Church & School'},
{compassLocation:'CHURCH: Muncie - St. Mary', customerId: 'BARA_00908', fullName: 'DIO LAF St. Mary Church (MUNCIE)', shortName:'Dio of Lafayette St. Mary Church', city: 'Muncie', dropdownText: 'St. Mary Church'},
{compassLocation:'CHURCH: Muncie - St. Lawrence', customerId: 'BARA_00909', fullName: 'DIO LAF St. Lawrence Church (Muncie)', shortName:'Dio of Lafayette St. Lawrence Church', city: 'Muncie', dropdownText: 'St. Lawrence Church'},
{compassLocation:'CHURCH: Westfield - St. Maria Goretti', customerId: 'BARA_00910', fullName: 'DIO LAF St. Maria Goretti@', shortName:'Dio of Lafayette St. Maria Goretti Parish & School (Westfield)', city: 'Westfield', dropdownText: 'St. Maria Goretti Parish & School'},
{compassLocation:'CHURCH: Cicero - Sacred Heart of Jesus', customerId: 'BARA_00912', fullName: 'DIO LAF Sacred Heart (Cicero)', shortName:'Dio of Lafayette Sacred Heart of Jesus (Cicero)', city: 'Cicero', dropdownText: 'Sacred Heart of Jesus'},
{compassLocation:'CHURCH: Delphi - St. Joseph', customerId: 'BARA_00917', fullName: 'DIO LAF St. Joseph (Delphi)', shortName:'Dio of Lafayette St. Joseph Catholic Church (Delphi)', city: 'Delphi', dropdownText: 'St. Joseph Catholic Church'},
{compassLocation:'CHURCH: Logansport - All Saints', customerId: 'BARA_00930', fullName: 'DIO LAF All Saints Parish@', shortName:'Diocese of Lafayette All Saints Parish', city: 'Logansport', dropdownText: 'All Saints Parish'},
{compassLocation:'CHURCH: West Lafayette - St. Thomas Aquinas', customerId: 'BARA_00931', fullName: 'DIO LAF St. Thomas Aquinas', shortName:'Diocese of Lafayette St. Thomas Aquinas Center', city: 'West Lafayette', dropdownText: 'St. Thomas Aquinas Center'},
{compassLocation:'CHURCH: Fishers - St. John Vianney', customerId: 'BARA_00940', fullName: 'DIO LAF St. John Vianney', shortName:'Diocese of Lafayette St. John Vianney Catholic Church', city: 'Fishers', dropdownText: 'St. John Vianney Catholic Church'},
{compassLocation:'CHURCH: Marion - St. Paul', customerId: 'BARA_00941', fullName: 'DIO LAF St. Paul (Marion)@', shortName:'Diocese of Lafayette St. Paul Catholic Church', city: 'Marion', dropdownText: 'St. Paul Catholic Church'},
{compassLocation:'CHURCH: Lake Village - St. Augusta', customerId: 'BARA_00943', fullName: 'DIO LAF St. Augusta@', shortName:'Diocese of Lafayette St. Augusta Church', city: 'Lake Village', dropdownText: 'St. Augusta Church'},
{compassLocation:'CHURCH: Gas City - Holy Family', customerId: 'BARA_00944', fullName: 'DIO LAF Holy Family(Gas City)@', shortName:'Diocese of Lafayette Holy Family Church', city: 'Gas City', dropdownText: 'Holy Family Church'},
{compassLocation:'CHURCH: Oxford - St. Patrick', customerId: 'BARA_00947', fullName: 'DIO LAF St. Patrick (Oxford)', shortName:'Diocese of Lafayette St. Patrick Church', city: 'Oxford', dropdownText: 'St. Patrick Church'},
{compassLocation:'CHURCH: Winamac - St. Peter', customerId: 'BARA_00954', fullName: 'DIO LAF St. Peter (Winamac)', shortName:'Diocese of Lafayette St. Peter Church', city: 'Winamac', dropdownText: 'St. Anne Catholic Church'},
{compassLocation:'CHURCH: Alexandria - St. Mary', customerId: 'BARA_00955', fullName: 'DIO LAF St. Mary (Alexandria)', shortName:'Diocese of Lafayette St. Mary Church', city: 'Alexandria', dropdownText: 'St. Mary Church'},
{compassLocation:'CHURCH: Rensselaer - St. Augustine', customerId: 'BARA_00956', fullName: 'DIO LAF St. Augustine Church-School', shortName:'Diocese of Lafayette St. Augustine Catholic Church and School', city: 'Rensselaer', dropdownText: 'St. Augustine Catholic Church & School'},
{compassLocation:'CHURCH: Zionsville - St. Alphonsus Liguori', customerId: 'BARA_00959', fullName: 'DIO LAF St. Alphonsus Liguori@', shortName:'Diocese of Lafayette St. Alphonsus Liguori Church', city: 'Zionsville', dropdownText: 'St. Alphonsus Liguori Church'},
{compassLocation:'CHURCH: Fowler - Sacred Heart of Jesus', customerId: 'BARA_00962', fullName: 'DIO LAF Sacred Heart (Fowler)', shortName:'Diocese of Lafayette Sacred Heart Church and School', city: 'Fowler', dropdownText: 'Sacred Heart Church and School'},
{compassLocation:'CHURCH: Elwood - St. Joseph', customerId: 'BARA_00964', fullName: 'DIO LAF St. Joseph (Elwood)', shortName:'Diocese of Lafayette St. Joseph Church', city: 'Elwood', dropdownText: 'St. Joseph Church'},
{compassLocation:'CHURCH: Carmel - Our Lady of Mount Carmel', customerId: 'BARA_00966', fullName: 'DIO LAF St. ElizabethAnnSeton', shortName:'Diocese of Lafayette St. Elizabeth Ann Seton Catholic Church', city: 'Carmel', dropdownText: 'St. Elizabeth Ann Seton Catholic Church'},
{compassLocation:'CHURCH: Monticello - Our Lady of the Lakes', customerId: 'BARA_00969', fullName: 'DIO LAF Our Lady of the Lakes@', shortName:'Diocese of Lafayette Our Lady of the Lakes Catholic Church', city: 'Monticello', dropdownText: 'Our Lady of the Lakes Catholic Church'},
{compassLocation:'CHURCH: Muncie - St. Francis of Assisi', customerId: 'BARA_00973', fullName: 'DIO LAF St. Francis of Assisi (Muncie)', shortName:'Diocese of Lafayette St. Francis of Assisi Church', city: 'Muncie', dropdownText: 'St. Francis of Assisi Church'},
{compassLocation:'CHURCH: Crawfordsville - St. Bernard', customerId: 'BARA_00982', fullName: 'DIO LAF St. Bernard@', shortName:'Diocese of Lafayette St. Bernard Parish', city: 'Crawfordsville', dropdownText: 'St. Bernard Parish'},
{compassLocation:'CHURCH: Hartford City - St. John the Evangelist', customerId: 'BARA_01057', fullName: 'DIO LAF St. JohntheEvangelist@', shortName:'Diocese of Lafayette St. John The Evangelist Church', city: 'Hartford City', dropdownText: 'St. John The Evangelist Church'},
{compassLocation:'CHURCH: Lafayette - St. Ann', customerId: 'BARA_01061', fullName: 'DIO LAF St. Ann Church', shortName:'Diocese of Lafayette St. Ann Church', city: 'Lafayette', dropdownText: 'St. Ann Church'},
{compassLocation:'CHURCH: Anderson - St. Ambrose', customerId: 'BARA_01089', fullName: 'DIO LAF St. Ambrose Parish', shortName:'Diocese of Lafayette St. Ambrose Parish', city: 'Anderson', dropdownText: 'St. Ambrose Parish'},
{compassLocation:'CHURCH: Lafayette - St. Boniface', customerId: 'BARA_01096', fullName: 'DIO LAF St. Boniface Church', shortName:'Dio of Lafayette St. Boniface Church', city: 'Lafayette', dropdownText: 'St. Boniface Church'},
{compassLocation:'CHURCH: DeMotte - St. Cecilia', customerId: 'BARA_01275', fullName: 'DIO LAF St. Cecilia Church DeMotte', shortName:'Dio of Lafayette St. Cecilia Church', city: 'DeMotte', dropdownText: 'St. Cecilia Church'},
{compassLocation:'CHURCH: Reynolds - St. Joseph', customerId: 'BARA_01281', fullName: 'DIO LAF St. Joseph (Reynolds)', shortName:'Dio of Lafayette St. Joseph Church ( Reynolds)', city: 'Reynolds', dropdownText: 'St. Joseph Church'},
{compassLocation:'CHURCH: Union City - St. Mary', customerId: 'BARA_01610', fullName: 'DIO LAF St. Mary (Union City)', shortName:'Diocese of Lafayette St. Mary Catholic Church', city: 'Union City', dropdownText: 'St. Mary Catholic Church'},
{compassLocation:'CHURCH: Portland - Immaculate Conception', customerId: 'BARA_01612', fullName: 'DIO LAF ImmacConceptionChurch', shortName:'Dio of Lafayette Immaculate Conception Church', city: 'Portland', dropdownText: 'Immaculate Conception Church'},
{compassLocation:'CHURCH: Remington - Sacred Heart', customerId: 'BARA_01746', fullName: 'DIO LAF SACRED HEART (Remington)', shortName:'Diocese of Lafayette Sacred Heart Catholic Church', city: 'Remington', dropdownText: 'Sacred Heart Catholic Church'},
{compassLocation:'CHURCH: Wheatfield - Sorrowful Mother', customerId: 'BARA_01774', fullName: 'DIO LAF Sorrowful Mother Parish@', shortName:'Diocese of Lafayette Sorrowful Mother Parish', city: 'Wheatfield', dropdownText: 'Sorrowful Mother Parish'},
{compassLocation:'SCHOOL: Carmel - Our Lady of Mount Carmel School', customerId: 'BARA_01782', fullName: 'DIO LAF Our Lady of Mt Carmel', shortName:'Diocese of Lafayette Our Lady of Mt. Carmel Church', city: 'Carmel', dropdownText: 'Our Lady of Mt. Carmel Church'},
{compassLocation:'CHURCH: Rochester - St. Joseph', customerId: 'BARA_02297', fullName: 'DIO LAF St. Joseph (Rochester)', shortName:'Diocese of Lafayette St. Joseph Catholic Church', city: 'Rochester', dropdownText: 'St. Joseph Catholic Church'},
{compassLocation:'CHURCH: Kentland - St. Joseph', customerId: 'BARA_02302', fullName: 'DIO LAF St. Joseph Church (Kentland)', shortName:'Diocese of Lafayette St. Joseph Church', city: 'Kentland', dropdownText: 'St. Joseph Church'},
{compassLocation:'CHURCH: Lafayette - St. Lawrence', customerId: 'BARA_02530', fullName: 'DIO LAF St. Lawrence Church (Lafayette)', shortName:'Diocese of Lafayette Saint Lawrence Catholic Church', city: 'Lafayette', dropdownText: 'St. Lawrence Catholic Church'},
{compassLocation:'CHURCH: Covington - St. Joseph', customerId: 'BARA_02607', fullName: 'DIO LAF St. Joseph Church (Covington)', shortName:'Diocese of Lafayette St. Joseph Church (Covington)', city: 'Covington', dropdownText: 'St. Joseph Church (Covington)'},
{compassLocation:'CHURCH: Attica - St. Francis Xavier', customerId: 'BARA_02608', fullName: 'DIO LAF St. Francis Xavier Church', shortName:'Diocese of Lafayette St. Francis Xavier Church', city: 'Attica', dropdownText: 'St. Francis Xavier Church'},
{compassLocation:'OTHER: Tipton - St. Joseph Retreat and Conference Center', customerId: 'BARA_02617', fullName: 'DIO LAF St. Joseph Retreat Conf Ctr', shortName:'Diocese of Lafayette St. Joseph Retreat and Conference Center', city: 'Tipton', dropdownText: 'St. Joseph Retreat and Conference Center'},
{compassLocation:'CHURCH: Dunkirk - St. Mary', customerId: 'BARA_02618', fullName: 'DIO LAF St. Mary (Dunkirk)', shortName:'Diocese of Lafayette St. Mary Church', city: 'Dunkirk', dropdownText: 'St. Mary Church'},
{compassLocation:'CHURCH: Earl Park - St. John the Baptist', customerId: 'BARA_02619', fullName: 'DIO LAF St. John Baptist Church (Earl Park)', shortName:'Diocese of Lafayette St. John the Baptist Church', city: 'Earl Park', dropdownText: 'St. John Baptist Church'},
{compassLocation:'CHURCH: Dunnington - St. Mary', customerId: 'BARA_02620', fullName: 'DIO LAF St. Mary (Dunnington)', shortName:'Diocese of Lafayette St. Mary Church', city: 'Fowler', dropdownText: 'St. Mary Church'},
{compassLocation:'CHURCH: Lafayette - Cathedral of St. Mary of the Immaculate Conception', customerId: 'BARA_02621', fullName: 'DIO LAF Cathedral of St. Mary Immaculate Conc', shortName:'Diocese of Lafayette Cathedral of St. Mary of the Immaculate Conception', city: 'Lafayette', dropdownText: 'St. Mary of the Immaculate Conception'},
{compassLocation:'CHURCH: Francesville - St. Francis Solano', customerId: 'BARA_02624', fullName: 'DIO LAF St. Frances Solano Church (Francesville)', shortName:'Diocese of Lafayette St. Frances Solano Church', city: 'Francesville', dropdownText: 'St. Frances Solano Church'},
{compassLocation:'CHURCH: Goodland - Sts. Peter and Paul', customerId: 'BARA_02625', fullName: 'DIO LAF St. Peter Paul Church (Goodland)', shortName:'Diocese of Lafayette St. Peter and Paul Church', city: 'Goodland', dropdownText: 'St. Peter Paul Church'},
{compassLocation:'CHURCH: Kewanna - St. Ann', customerId: 'BARA_02626', fullName: 'DIO LAF St. Ann Church (Kewanna)', shortName:'Diocese of Lafayette St. Ann Church', city: 'Kewanna', dropdownText: 'St. Ann Church'},
{compassLocation:'CHURCH: Otterbein - St. Charles', customerId: 'BARA_02627', fullName: 'DIO LAF St. Charles Church (Otterbein)', shortName:'Diocese of Lafayette St. Charles Church', city: 'Otterbein', dropdownText: 'St. Charles Church'},
{compassLocation:'CHURCH: Winchester - St. Joseph', customerId: 'BARA_02629', fullName: 'DIO LAF St. Joseph Church (Winchester)', shortName:'Diocese of Lafayette St. Joseph Church (Winchester)', city: 'Winchester', dropdownText: 'St. Joseph Church'},
{compassLocation:'CHURCH: Monterey - St. Anne', customerId: 'BARA_02630', fullName: 'DIO LAF St. Anne Church (Monterey)', shortName:'Diocese of Lafayette St. Anne Church', city: 'Monterey', dropdownText: 'St. Anne Church'},
{compassLocation:'CHURCH: Trinity - Holy Trinity', customerId: 'BARA_02661', fullName: 'DIO LAF Holy Trinity Church (Bryant)', shortName:'Dio of Lafayette Holy Trinity Church', city: 'Bryant', dropdownText: 'Holy Trinity Church'},
{compassLocation:'SCHOOL: Muncie - St. Michael School', customerId: 'BARA_03352', fullName: 'DIO LAF St. Michael School (Muncie)', shortName:'Diocese of Lafayette St. Michael School', city: 'Muncie', dropdownText: 'St. Michael School'}
];




//add flag for school fields
export const STATES = [
{ value:"AL", label: "Alabama"},
{ value:"AK", label: "Alaska"},
{ value:"AZ", label: "Arizona"},
{ value:"AR", label: "Arkansas"},
{ value:"CA", label: "California"},
{ value:"CO", label: "Colorado"},
{ value:"CT", label: "Connecticut"},
{ value:"DE", label: "Delaware"},
{ value:"FL", label: "Florida"},
{ value:"GA", label: "Georgia"},
{ value:"HI", label: "Hawaii"},
{ value:"ID", label: "Idaho"},
{ value:"IL", label: "Illinois"},
{ value:"IN", label: "Indiana"},
{ value:"IA", label: "Iowa"},
{ value:"KS", label: "Kansas"},
{ value:"KY", label: "Kentucky"},
{ value:"LA", label: "Louisiana"},
{ value:"ME", label: "Maine"},
{ value:"MD", label: "Maryland"},
{ value:"MA", label: "Massachusetts"},
{ value:"MI", label: "Michigan"},
{ value:"MN", label: "Minnesota"},
{ value:"MS", label: "Mississippi"},
{ value:"MO", label: "Missouri"},
{ value:"MT", label: "Montana"},
{ value:"NE", label: "Nebraska"},
{ value:"NV", label: "Nevada"},
{ value:"NH", label: "New Hampshire"},
{ value:"NJ", label: "New Jersey"},
{ value:"NM", label: "New Mexico"},
{ value:"NY", label: "New York"},
{ value:"NC", label: "North Carolina"},
{ value:"ND", label: "North Dakota"},
{ value:"OH", label: "Ohio"},
{ value:"OK", label: "Oklahoma"},
{ value:"OR", label: "Oregon"},
{ value:"PA", label: "Pennsylvania"},
{ value:"RI", label: "Rhode Island"},
{ value:"SC", label: "South Carolina"},
{ value:"SD", label: "South Dakota"},
{ value:"TN", label: "Tennessee"},
{ value:"TX", label: "Texas"},
{ value:"UT", label: "Utah"},
{ value:"VT", label: "Vermont"},
{ value:"VA", label: "Virginia"},
{ value:"WA", label: "Washington"},
{ value:"WV", label: "West Virginia"},
{ value:"WI", label: "Wisconsin"},
{ value:"WY", label: "Wyoming"}
]